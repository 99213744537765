<template>
    <div class="container" id="submitEmail">
        <h1>
            <img id="logo" src="../assets/sdsa-logo.svg" alt="SDSA Logo" width="40" /> Join SDSA's Slack
        </h1>
        <p>Welcome! Slack access is limited to Seattle DSA chapter members. Please enter the email address you used when signing up as a member of DSA.</p>
        <p v-if="error" class="text-danger">{{error}}</p>
        <p v-if="success" class="text-success">{{success}}</p>
        <form v-if="!success" v-on:submit.prevent>
            <div class="mb-3 .col-lg-5">
                <label for="email" class="form-label">Email:</label>
                <input type="text" class="form-control" id="email" v-model="email" autocomplete="off">
            </div>
            <button
                type="button"
                class="btn btn-primary"
                @click="submitEmail"
            >Submit</button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'SubmitEmail',
    data() {
        return {
            email: '',
            error: '',
            success: '',
        }
    },
    methods: {
        submitEmail() {
            this.$emit('submit-email', this.email);
            if (window.grecaptcha.getResponse() !== '') {
                this.email = '';
                this.success = 
                    'Thank you! Please check ' + this.email + 
                    ' for an invitation to join the Seattle DSA Slack. ' +
                    'If you do not receive an invitation, please email helpdesk@seattledsa.org';
            }
            if (window.grecaptcha.getResponse() === '') {
                this.error = 'Please complete the reCAPTCHA and submit again';
            }
        }
    }
}
</script>


<style>

p {
    width: 95%;
}

h1 {
    font-size: 35px;
    margin-top: 3px
}

img {
    margin-top: -10px
}

form {
    width: 100%;
}

.form-label {
    padding-top: 10px;
}

#submitEmail {
    padding-bottom: 20px;
    margin: 0px;
}

.btn-primary {
    color: #F5F5F7;
    background-color: #EC1F27;
    border: 2px solid #EC1F27;
}

.btn-primary:hover {
    color: #F5F5F7;
    background-color: #F8AAAD;
    border: 2px solid #EC1F27;
}
 
</style>