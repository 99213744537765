<template>
  <div>
    <MainNavBar />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <SubmitEmail @submit-email="processSubmitEmail" />
          <div class="g-recaptcha" :data-sitekey="siteKey"></div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitEmail from './components/SubmitEmail.vue';
import MainNavBar from './components/MainNavBar.vue';

// Check the current website URL then use it to determine the API endpoint
const BASE_API_ENDPOINT = window.location.href.includes('welcome.seattledsa.org') ?
  process.env.VUE_APP_API_ENDPOINT_PROD:
  process.env.VUE_APP_API_ENDPOINT_DEV;

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

export default {
  name: 'App',
  components: {
    SubmitEmail,
    MainNavBar,
  },
  data () {
    return {
      siteKey: RECAPTCHA_SITE_KEY,
    };
  },
  methods: {
    async processSubmitEmail(email) {
      const token = await window.grecaptcha.getResponse()
      await fetch(BASE_API_ENDPOINT + '/welcome', {
        method: 'POST',
        body: JSON.stringify(
          {
            email: email,
            recaptchaToken: token,
          }
        ),
      });
      window.grecaptcha.reset();
    },
  },
};
</script>

<style scoped>
body {
  background-color: #F5F5F7;
}

#app {
  width: 100%;
}

.mainButtonGroup {
  margin: 10px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.form-label {
  font-size: 28px;
}

#errorMessage {
  width: 100%;
  z-index: 1000;
}

.g-recaptcha {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
</style>