<template>
    <nav id="NavBar" class="navbar bg-secondary" data-bs-theme="dark">
        <div class="container-fluid">
            
            <a class="navbar-brand">Seattle Democratic Socialists of America</a>
        </div>
    </nav>    
</template>

<script>
export default {
    name: 'MainNavBar',
}
</script>

<style scoped>
#NavBar {
    margin-bottom: 20px;
    background-color: #EC1F27 !important;
}
</style>
